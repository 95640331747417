import { stringify } from "qs";
import { useQuery } from "react-query";

import { request } from "services/api";
import { companyNameDTO } from "types/utils";

type QueryData = companyNameDTO[];
type Props = {
	gtin?: string;
	attribute?: string;
};
const useGetCompanyProductsQuery = ({ gtin, attribute }: Props = {}) => {
	const query = useQuery(
		["/integration-mxik/company/get/basket-products", attribute],
		async () => {
			const response = await request.get<QueryData>(
				`/international/gs1/short-info-by-gtin/${gtin}?${stringify({ name: attribute })}`
			);

			return response;
		},
		{ enabled: !!gtin }
	);

	return query;
};

export default useGetCompanyProductsQuery;
